import React from "react"
import { Helmet } from "react-helmet"
import Search from "../components/search"
import NamesList from "../components/namesList"
import Divedowns from "../components/divedowns"
import Layout from "../components/layout"

import Ad from "../components/ad"
import { graphql, Link } from "gatsby"
import _ from "lodash"

import HeaderPic from "../components/headerpic"
import headerPic from "../../static/headerpics/vornamensuche.png"


export default function Home({ data }) {
  const names = _.shuffle(data.allNamesJson.nodes)
  const divedowns = data.pagesJson.divedowns.filter(
    d => (!['Zwei Anfangsbuchstaben', 'Drei Anfangsbuchstaben'].includes(d.attribute.category))
  )
  const searchExamples = _.shuffle(data.searchExamples.nodes
    .filter(page => page.attributes.length <= 2))
    .slice(0, 7)

  return (
    <Layout>
      <Helmet>
        <title>Vornamensuche mit Herkunft, Bedeutung und Beliebtheit</title>
        <meta name="description"
          content="Über 16000 männliche und weibliche Vornamen mit Herkunft, 
                Bedeutung und Beliebtheit. "/>
        <meta name="keywords"
          content="vornamensuche, vornamen, namen, jungennamen, mädchennamen" />
        <link rel="canonical" href='/' />
      </Helmet>
      <div className="paper" >
        <HeaderPic imagePath={headerPic} />
        <h1 style={{ textAlign: "center" }}>Die schönsten Vornamen für Ihr Kind</h1>
        <p style={{ textAlign: "center" }}>16151 Vornamen nach {' '}
          <Link to="/nach-geschlecht">Geschlecht</Link>, {' '}
          <Link to="/nach-herkunft">Herkunft</Link>, {' '}
          <Link to="/nach-bedeutung">Bedeutung</Link>, {' '}
          <Link to="/nach-beliebtheit">Beliebtheit</Link>, {' '}
          <Link to="/namenslexikon">Anfangsbuchstaben, Länge und Silben</Link>.
          </p>
      </div>
      <Search searchExamples={searchExamples} />
      <Ad id="adnz_maxiboard_1" />
      <NamesList names={names} initial_limit={10} />
      <Ad id="adnz_maxiboard_2" />
      <Divedowns divedowns={divedowns} />
      <Ad id="adnz_maxiboard_3" />
    </Layout>
  )
}

export const query = graphql`
  query {
    searchExamples : allPagesJson (sort: {fields: number_of_names, order: DESC}, limit: 100) {
      nodes {
        attributes {
          imagePath
          id
        }
        number_of_names
        title
        url
      }
    }
    pagesJson(url: {eq: "" }) {
      divedowns {
        attribute {
          imagePath
          category
          id
          title
          latest_births
          births {
            years
            births
          }
          popularity
          trend
        }
        number_of_names
        subpage_url
      }
    }
    allNamesJson(
      sort: {order: DESC, fields: popularity},
      limit: 200,
      ) {
      nodes {
        gender
        id
        meaning
        name
        origin
        popularity
        births {
          births
          years
        }
      }
    }
  }`